/* #region colors */

:root {
  --nav-bar-color: #b7b7b7;
  --nav-bar-font-color: #d00f0f;
  --nav-bar-active-color: #f5f5f5;
  --nav-bar-active-font-color: #000000;
  --nav-bar-hover-color: #c0c0c0;
  --nav-bar-hover-font-color: #ffffff;
  --element-color: #13064e;
  --element-font-color: #ffffff;
  --element-active-color: #D0A946;
  --element-active-font-color: #000000;
  --element-hover-color: #f2d701;
  --element-hover-font-color: #000000;
}

$nav-bar-color: var(--nav-bar-color);
$nav-bar-font-color: var(--nav-bar-font-color);
$nav-bar-active-color: var(--nav-bar-active-color);
$nav-bar-active-font-color: var(--nav-bar-active-font-color);
$nav-bar-hover-color: var(--nav-bar-hover-color);
$nav-bar-hover-font-color: var(--nav-bar-hover-font-color);
$element-color: var(--element-color);
$element-font-color: var(--element-font-color);
$element-active-color: var(--element-active-color);
$element-active-font-color: var(--element-active-font-color);
$element-hover-color: var(--element-hover-color);
$element-hover-font-color: var(--element-hover-font-color);

/* #endregion */



/* #region App*/



/* Common styles for both panels */

.home-page-container {
  height: calc(100vh - 75px);
  overflow: hidden;

}

a { 
  color: #000000;
  text-decoration: none;
}

a:hover { 
  color: #e12928;
  text-decoration: none;
}
/* Common logo style for smaller screens */
.logo {
  cursor: pointer;
  background-repeat: no-repeat;
  // background-image: url(./themes/dt-logo.png);
  background-image: url(./themes/logo.png);
  background-size: contain;
  width: 100px;
  height: 70px;
  display: cover;
  margin-right: 25px;
  margin-top: 10px
}



/* Apply common styles to all screen sizes */
.App {
  text-align: center;
}

img {
  max-width: 40%;
  /* Set the maximum width to the width of the container */
  height: auto;
  /* Maintain the image's aspect ratio */
}



.App-header {
  // background-color: #db2323;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  position: absolute;
}

.App-link {
  color: #61dafb;
}



.content-inside {
  // display: flex;
  // padding-left: 30px;
  margin-left: 69px;
  font-size: 12px;
  height: 100%;
  position: absolute;
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .content-inside {
    padding-left: 45px;
  }
}

.p-treenode-content {
  cursor: pointer;

  &:hover {
    background-color: lightgray;
    border: 0.5px lightgray;
  }
}


.home-page-container {
  width: 100%;
  position: relative;
  top: -5px;
  height: 120;
  display: flex;
  overflow: hidden;
}


.collapsed {
  background-color: silver;
  width: 40px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: fixed;
}

@media (max-width: 800px) {

  .home-page-container {
    display: flex;
  }

  .collapsed {
    position: fixed;
  }
}


.collapse-button :hover {
  color: $nav-bar-hover-font-color;
}


.nav-bar {
  height: 75px;
}


.p-breadcrumb {
  background: none;
  border: none;
}

.dataview {
  height: 100%;
}

.dataview .card {
  height: 100%;
}

.dataview .card .p-dataview {
  height: 100%;
}


.dataview .card .p-dataview-content {
  height: calc(100% - 120px);
  overflow: auto;
  background-color: #faf9f8;
  width: 100%
}

.dataview .card .p-dataview-content .p-grid {
  width: calc(100% - 15px);
  display: flex;
  position: absolute;
  left: 10px;
  gap: 15px;
}


.main-container {
  padding: 2px;
  height: calc(100% - 15px);
  background-color: white;
  width: 100%!important;
}

.app-container {
  height: calc(100vh - 90px);
}

@media (max-width: 600px) {
  .app-container {
    height: calc(100vh - 0px);
  }
}



/* #endregion */



/* #region App-Header */


.nav-content {
  display: flex;
  align-items: center;
}

.logo-nav {
  margin-right: 12px;
  width: 68px;
  height: 30px;
  margin-bottom: 10px;
}

.p-breadcrumb {
  background: transparent !important;
  border: 0px solid #dee2e6 !important;
  border-radius: 0px !important;
  padding: 2px !important;
  height: 40px;
  color: rgb(255, 255, 255);
  margin-top: 5px;
  overflow: hidden;
}



.header-left-block {
  float: left;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 5px;
}



.header-left-block .p-breadcrumb {
  background: transparent !important;
  border: 0px solid #dee2e6 !important;
  border-radius: 0px !important;
  padding: 2px !important;
  color: white;
  margin-top: 5px;
  overflow: hidden;
}



@media screen and (min-width: 768px) {
  .header-left-block .p-breadcrumb {
    background: transparent !important;
    border: 0px solid #dee2e6 !important;
    border-radius: 0px !important;
    padding: 2px !important;
    height: 25px;
    color: white;
    margin-top: 15px;
    overflow: hidden;
  }
}


/* Media query for smaller screens */

@media screen and (min-width: 768px) {
  .header-left-block .p-breadcrumb {
    background: transparent !important;
    border: 0px solid #dee2e6 !important;
    border-radius: 0px !important;
    padding: 2px !important;
    height: 25px;
    color: white;
    margin-top: 15px;
    overflow: hidden;
  }
}


.header-right-block {
  float: right;
  height: 80px;
  color: black;
  margin-top: 20px;
  margin-right: 20px;
}

.icon-button {
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}

.p-menuitem-text {

  &:hover {
    color: $nav-bar-active-color
  }

}

.header-bar {
  height: 80px;
  background-color: #ffffff;
  border-bottom: 1px solid #c1c1c1;
}



.header-left-block .p-breadcrumb ul .p-menuitem-link .p-menuitem-text {

  color: $nav-bar-font-color;

  &:hover {
    color: $nav-bar-hover-font-color ;
  }
}

.header-left-block .p-breadcrumb ul li.p-breadcrumb-chevron {
  color: $nav-bar-font-color;
}


.header-left-block .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: rgb(0, 0, 0);
}

.header-left-block .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon:hover {
  color: $nav-bar-hover-font-color !important;
}



.user-profile-button {
  background-color: $element-color !important;
  color: white !important;
  border: #faf9f8 !important;

  &:hover {
    color: $element-hover-color!important;
  }
}

.admin-menu-button {
  color: white !important;
  border: #faf9f8 !important;

  &:hover {
    color: $element-hover-color!important;
  }
}

.sign-out-button {

  border: 1px solid white !important;
  background-color: $element-color !important;

  &:hover {
    background-color: $element-hover-color !important;
    color: $element-hover-font-color !important;
  }
}

button.p-overlaypanel-close.p-link {
  background-color: $element-color !important;
  border: #faf9f8 !important;

  &:hover {
    background-color: $element-hover-color!important;
    color: $element-hover-font-color !important;
  }
}

/* #endregion */


.repo-add-button {

  background-color: $element-color !important;
  border: #faf9f8 !important;

  &:hover {
    background-color: $element-hover-color !important;
    color: $element-hover-font-color !important;
  }
}

.repo-sync-button {

  background-color: $element-color !important;
  border: #faf9f8 !important;

  &:hover {
    background-color: $element-hover-color !important;
    color: $element-hover-font-color !important;
  }
}

.repo-edit-button {

  background-color: $element-color !important;
  border: #faf9f8 !important;

  &:hover {
    background-color: $element-hover-color !important;
    color: $element-hover-font-color !important;
  }
}

.repo-delete-button {

  background-color: $element-color !important;
  border: #faf9f8 !important;

  &:hover {
    background-color: $element-hover-color !important;
    color: $element-hover-font-color !important;
  }
}




/* #region login */

#login-container {
  background-color: #ffffff!important;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  // background-image: url(./themes/bg2.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;

  .login-forgot-block {
    cursor: pointer;
  }

  .login-forgot-font-block {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .lml-user-login {
    background-color: white;
    color: white;
    border-radius: 15px;
    gap: 30px;
    padding: 25px 0;
    text-align: center;
  }

  .lml-login-block {
    margin-bottom: 30px;
    color: white;
  }

  .lml-login-label {
    color: white;
  }


  .login-email-block {
    padding-left: 10px;
    padding-right: 10px;

    .login-input-email {
      background-color: white;
      color: black;
      width: 100%;
      text-align: center;
      margin-bottom: -10px;
      border: 1px solid black;
    }

    .forgot-input-email {
      background-color: white;
      color: black;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
      border: 1px solid black;
    }
  }


  .login-password-block {
    padding-left: 10px;
    padding-right: 10px;

    .login-input-password {
      background-color: white;
      color: black;
      width: 100%;
      text-align: center;
      margin-top: -10px;
      border: 1px solid black;
    }

    .password-help {
      background-color: black;
    }
  }

  .user-button-container {
    padding-left: 10px;
    padding-right: 10px;

    .user-login-button {
      width: 100%;
      height: 40px;
      color: white;
      border-radius: 30px;

      &:hover {
        border: 1px solid;
        box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 0, 0, 0.2);
        outline-color: rgba(225, 4, 4, 0.884);
        outline-offset: 15px;
        text-shadow: 1px 1px 2px #3c3c3c;
      }
    }
  }

  .microsoft-container {
    padding-left: 10px;
    padding-right: 10px;

    .microsoft-button {
      width: 100%;
      background-color: #bc111a;
      border: 1px solid #c3c3c3;
      border-radius: 30px;

      &:hover {
        background-color: #c3c3c3;
        border: 1px solid #c3c3c3;
        color: black;
      }
    }
  }
}




.login-app-container {
  height: calc(100vh - 10px);
}

.lml-app-title {
  margin-left: calc(50% - 95px);
  font-size: 48px;
  color: white;
}


.lml-user-login {
  border-radius: 2px;
  background-color: white;
}



.post-login {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  height: 100px;
}



.post-login-spinner {
  width: 100% !important;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 75px !important;
}



.lml-login-label {
  margin-bottom: 5px !important;
}


.lml-error-login {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  background-color: black;
  height: auto;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  color: red;
}



@media (min-width: 300px) {
  .lml-user-login {
    margin-left: 0px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    margin-top: 5px;
  }

  #login-div {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px;
    width: 100%;
    height: 100%;
  }

  .lml-login-input {
    width: 300px;
  }

  .lml-login-label {
    width: 300px;
  }

  .lml-login-block {
    margin-bottom: 15px;
    width: 200px;
  }
}

@media (min-width: 1024px) {
  #login-div {
    position: absolute;
    left: calc(50% - 200px);
    top: 50px;
    padding: 20px;
    width: 400px;
    height: 600px;
  }


  .lml-login-input {
    width: 300px;
  }


  .lml-login-label {
    width: 300px;
  }


  .lml-login-block {
    margin-bottom: 10px;
    width: 200px;
  }
}

@media (min-width: 1500px) {
  .lml-login-input {
    width: 300px;
  }


  .lml-login-label {
    width: 300px;
  }
  #login-div {
    position: absolute;
    left: calc(50% - 200px);
    top: 50px;
    padding: 20px;
    width: 400px;
    height: 600px;
  }



  .lml-login-block {
    margin-bottom: 10px;
    width: 200px;
  }


  .lml-user-login {
    margin-left: 0px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
  }
}
